.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
}

.closeButton {
  position: absolute;
  right: 16px;
  top: 16px;
}

.popupContainer {
  position: absolute;
  left: 20%;
  right: 20%;
  top: 23%;
  bottom: 23%;
  margin: auto;
  color: white;
  width: 399px;
  // height: 427px;
  position: fixed;
  padding: 36px 24px;
  border-radius: 12px;
  background: #1A1B2F;
  box-shadow: 0px 16px 24px 0px rgba(12, 13, 33, 0.60);
}
