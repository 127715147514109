.minerTable {
  color: white
}

.createMinerButton {
  display: flex;
  align-items: center;
  color: #00F0FF;
  font-family: Lato;
  // font-size: 11px;
  font-style: normal;
  font-weight: 700;
  // line-height: 11px;
}

.green {
  color: #00CF67;
}
