.header {
  width: 100%;
  display: flex;
  padding: 12px;
  padding-left: 19px;
  position: fixed;
  left: 0;
  top: 0;
  h2 {
    padding-left: 8px;
    color: #FFF;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    letter-spacing: 2.8px;
  }
}
