.App {
  text-align: center;
  margin-top: 70px;
  margin-left: 32px;
  display: flex;
  .DetailPage {
    width: 50vw;
  }
  .MapSide {
    margin: auto;
  }
}

.green {
  color: #00CF67;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
